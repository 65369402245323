import Decimal from '@st/decimal'
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import { useCasinoStore } from '@st/casino/stores/useCasinoStore'
import type { CasinoLastBet } from '@st/site/components/LastBets/types'
import type { Ref } from 'vue'
import type { IconName } from '@st/ui/components/StIcon/types'

export interface UseCasinoBetReturn {
  categoryIcon: Ref<string | null>
  categoryName: Ref<string>
  date: Ref<string>
  relativeDate: Ref<string>
  currencyIcon: Ref<IconName>
  cryptoBetsAmount: Ref<string>
  fiatBetsAmount: Ref<string>
  rate: Ref<string | null>
  formattedRate: Ref<string>
  betResultClass: Ref<'positive' | 'negative' | 'neutral'>
  cryptoAmount: Ref<string>
  fiatBetResult: Ref<string>
  gameLink: Ref<string>
  winsAmountInGameCurrency: Ref<string>
}

export function useCasinoBet(bet: Ref<CasinoLastBet>): UseCasinoBetReturn {
  const { parseDate } = useDate()
  const { currencies } = storeToRefs(useCurrenciesStore())
  const { getCategoryByCode } = useCasinoStore()
  const { format: formatCrypto } = useCryptoFormatter()
  const { format: formatCryptoWin } = useCryptoFormatter({
    signDisplay: 'exceptZero',
  })

  const gameCurrencyCode = computed(
    () => currencies.value[bet.value.gameCurrencyId]?.code ?? 'USD',
  )

  const { format: formatFiat } = useCurrencyFormatter({
    currency: gameCurrencyCode,
    signDisplay: 'never',
  })

  const currencyIcon = computed(
    () => currencies.value?.[bet.value.currencyId]?.icon ?? '',
  )
  const category = computed(() => getCategoryByCode(bet.value.categoryCode))
  const cryptoBetsAmount = computed(() => formatCrypto(bet.value.betsAmount))
  const date = computed(() => parseDate(bet.value.updatedAt).fromNow())
  const relativeDate = computed(() =>
    parseDate(bet.value.updatedAt).format('LL LT'),
  )
  const categoryName = computed(
    () => category.value?.name || bet.value.categoryCode,
  )
  const categoryIcon = computed(() => category.value?.icon ?? null)
  const gameLink = computed(() => `/casino/games/${bet.value.gameId}?mode=real`)
  const fiatBetsAmount = computed(() =>
    formatFiat(bet.value.betsAmountInGameCurrency),
  )

  const rate = computed(() => {
    const winsAmount = bet.value.winsAmount || '0'
    const betsAmount = bet.value.betsAmount || '0'
    if (betsAmount === '0') return null
    return new Decimal(winsAmount).dividedBy(betsAmount).toFixed(2)
  })

  const formattedRate = computed(() =>
    rate.value === null ? '—' : `${rate.value}x`,
  )
  const winsAmountInGameCurrency = computed(
    () => bet.value.winsAmountInGameCurrency ?? '0',
  )
  const betResult = computed(() => {
    const betRate = new Decimal(rate.value ?? 0)
    const winsAmount = bet.value.winsAmount ?? '0'
    const betsAmount = bet.value.betsAmount ?? '0'
    const betsAmountInGameCurrency = bet.value.betsAmountInGameCurrency ?? '0'

    if (betRate.greaterThanOrEqualTo(1)) {
      return {
        fiat: winsAmountInGameCurrency.value,
        crypto: winsAmount,
      }
    }

    return {
      fiat: new Decimal(winsAmountInGameCurrency.value)
        .minus(betsAmountInGameCurrency)
        .toString(),
      crypto: new Decimal(winsAmount).minus(betsAmount).toString(),
    }
  })

  const cryptoAmount = computed(() => formatCryptoWin(betResult.value.crypto))
  const fiatBetResult = computed(() => formatFiat(betResult.value.fiat))

  const betResultClass = computed(() => {
    const betRate = new Decimal(rate.value ?? 0)

    if (betRate.greaterThanOrEqualTo(1)) {
      return 'positive'
    }
    if (betRate.lessThan(1)) {
      return 'negative'
    }
    return 'neutral'
  })
  return {
    categoryIcon,
    categoryName,
    date,
    relativeDate,
    currencyIcon,
    cryptoBetsAmount,
    fiatBetsAmount,
    rate,
    formattedRate,
    betResultClass,
    cryptoAmount,
    fiatBetResult,
    gameLink,
    winsAmountInGameCurrency,
  }
}
